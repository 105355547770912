* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #18191f;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

@-moz-document url-prefix() {
  * {
    font-weight: 300;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
